import { IconButton, Stack, Switch, Typography } from "@mui/material";
import React from "react";
import TableWrapper from "../TableWrapper";
import { toast } from "react-toastify";
import { updateContent } from "src/utils/AdminApi";
import { useAdmins } from "src/pages/Admins";
import { Link } from "react-router-dom";
import InfoIcon from "@mui/icons-material/Info";
import { baseURL } from "src/config";
const headerColumns = ["التاريخ", "القيمة", "مدفوع", "المستلم", "..."];

export default function TenantPaymentsList({
  payments,
  refetch,
  showSite = false,
}) {
  const { admins = [] } = useAdmins();
  const update = async (paymentId, values) => {
    await toast.promise(
      updateContent("tenant-payment.tenant-payment", paymentId, values),
      {
        loading: "جاري التحديث",
        success: "تم التحديث بنجاح",
        error: "حدث خطا",
      }
    );
    refetch();
  };

  return (
    <div>
      <TableWrapper
        align="start"
        headerColumns={
          showSite ? ["#", "المتجر", ...headerColumns] : ["#", ...headerColumns]
        }
        dataRows={payments.map((p, i) => {
          const link = `${baseURL}/admin/plugins/content-manager/collectionType/application::tenant-payment.tenant-payment/${p.id}`;
          const res = [
            <a target="_blank" href={link} rel="noreferrer">
              {i}
            </a>,
          ];
          if (showSite)
            res.push(
              <Link
                style={{ textDecoration: "none", color: "#0D6659" }}
                to={`/tenants/${p.tenant.id}`}
              >
                {p.tenant.site_name}
              </Link>
            );

          res.push(
            ...[
              p.created_at?.split("T")[0],
              p.amount,
              <Switch
                size="small"
                checked={p.paid}
                onChange={(e, isChecked) =>
                  update(p.id, {
                    paid: isChecked,
                  })
                }
              />,
              admins.find((a) => a.id == p.received_by)?.firstname ?? "-",
              <IconButton
                onClick={(e) =>
                  alert(
                    `bundle: ${p.bundle}\nexpiration date on ${
                      p.expiration_date
                    }\n${p.note ? `note:${p.note}` : ""}\n`
                  )
                }
                style={{ fontSize: 16 }}
                size="small"
              >
                <InfoIcon fontSize="inherit" />
              </IconButton>,
              // admins.find((a) => a.id == p.created_by)?.firstname ?? "-",
            ]
          );

          return res;
        })}
      />
    </div>
  );
}

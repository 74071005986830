/* eslint-disable no-restricted-globals */
import { Box, Button, Card, Container, Divider, Grid, Stack, Typography } from "@mui/material"
import { sumBy, unary } from "lodash"
import React, { useEffect, useMemo } from "react"
import { Link, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import AddPropertyModal from "src/components/AddPropertyModal"
import CopyButton from "src/components/CopyButton"
import { DynamicForm } from "vetrina-ui"
import { baseURL } from "src/config"
import { getSubscripitonStatusString } from "src/lib/subscription"
import { addTenantAdmin, clearStock, fetchContent, renewSubscription, updateContent, updateUserPassword } from "src/utils/AdminApi"
import { makeHttpRequest } from "src/utils/requestBuilder"
import { string } from "yup"
import { StatsItem } from "src/components/StatsItem"
import AccordionWrapper from "src/components/AccordionWrapper"
import { handlePromise } from "src/utils/toastUtils"
import { confirmAction } from "src/utils/confirm"
import showDynamicFormModal from "src/components/showDynamicFormModal"
import { useAdmins } from "../Admins"
import TenantPaymentsList from "src/components/tenant-payments/TenantPaymentsList"
import TenantConfigForm from "./TenantConfigForm"
import TenantBetaFeaturesForm from "./TenantBetaFeaturesForm"
import FileUploadField from "src/components/ImageCropPicker/FileUploadField"
import ImageCropPicker from "src/components/ImageCropPicker/ImageCropPicker"
import useUser from "src/hooks/useUser"

export default function TenantDetailsPage() {
  const { user, isAdmin } = useUser()
  const [state, setstate] = React.useState(false)
  /**
   * @type {[import("vetrina-types").Attributes['tenant']]}
   */
  const [tenant, setTenant] = React.useState(null)
  const [properties, setProperties] = React.useState([])
  const { admins } = useAdmins()

  const [stats, setStats] = React.useState({
    lastOrderDate: ".",
    activeOrdersCount: ".",
    activeOrdersIncome: ".",
    income: ".",
    deliveredOrdersCount: ".",
    productsCount: ".",
  })

  const { id } = useParams()

  const fetchTenant = async () => {
    const res = await fetchContent("tenant.tenant/" + id)
    setTenant(res)
  }
  const fetch = async () => {
    // TODO make request

    const res = await fetchTenant()

    if (!isAdmin) return

    const orders = await fetchContent(`guest-orders.guest-orders?pageSize=-1&TenantID=${id}&_sort=id:DESC`)

    const deliveredOrders = orders.results.filter((o) => o.status === "delivered")
    const activeOrders = orders.results.filter((o) => o.status !== "delivered" && o.stats !== "cancelled")

    setStats({
      lastOrderDate: orders?.results?.[0]?.created_at?.split("T")?.[0] ?? "",
      activeOrdersCount: activeOrders.length,
      activeOrdersIncome: sumBy(activeOrders, "total_price"),
      deliveredOrdersCount: deliveredOrders.length,
      income: sumBy(deliveredOrders, "total_price"),
    })

    const products = await fetchContent(`products.products?pageSize=-1&TenantID=${id}`)

    // console.log(products);

    setStats((s) => ({ ...s, productsCount: products.results.length }))
  }
  useEffect(() => {
    fetch()
    fetchContent(`property.property?pageSize=-1&TenantID=${id}`).then((p) => setProperties(p.results))
  }, [])

  const renew = async (duration, price, bundleValue) => {
    // if (!window.confirm('متاكد؟')) return;
    const {
      received_by,
      price: _price,
      paid,
    } = await showDynamicFormModal({
      formConfig: {
        inputs: [
          {
            label: "القيمة",
            placeholder: "القيمة",
            name: "price",
            type: "number",
            value: price,
          },
          {
            valueKey: "id",
            labelKey: "firstname",
            options: admins,
            label: "المستلم",
            placeholder: "المستلم",
            name: "received_by",
            type: "picker",
            value: "",
          },
          {
            label: "تم الاستلام",
            placeholder: "تم الاستلام",
            name: "paid",
            type: "checkbox",
            value: false,
          },
        ],
      },
    })

    try {
      await renewSubscription(duration, _price, bundleValue, id, paid, received_by || undefined)
      toast.success("تم تجديد الاشتراك بنجاح بقيمة,", _price)
      fetchTenant()
    } catch (err) {
      toast.error("حدث خطا، شوف اللوق")
      console.log(err)
    }
  }
  const tempRenew = async () => {
    // if (!window.confirm('متاكد؟')) return;
    let numberOfDays = prompt("number of days", 1)
    if (numberOfDays == null) return

    try {
      await renewSubscription(numberOfDays, 0, "مجاني", id)
      toast.success("تم تجديد الاشتراك بنجاح ,")
    } catch (err) {
      toast.error("حدث خطا، شوف اللوق")
      console.log(err)
    }
  }

  if (!tenant) return

  return (
    <Container sx={{ mb: 9 }}>
      {tenant && (
        <>
          <DynamicForm
            submitButtonText="تحديث"
            config={{
              inputs: [
                {
                  name: "logo",
                  value: tenant?.logo?.id,
                  component: ({ formik, input }) => {
                    return (
                      <FileUploadField
                        value={formik.values[input.name]}
                        defaultImage={tenant?.logo?.url}
                        label={"شعار الموقع"}
                        onChange={(newValue) => {
                          formik.setFieldValue(input.name, newValue)
                        }}
                      />
                    )
                  },
                },
                {
                  label: "hostname",
                  placeholder: "hostname",
                  name: "host",
                  type: "text",
                  value: tenant.host,
                },
                {
                  label: "custom domain name",
                  placeholder: "custom domain name",
                  name: "primary_host",
                  type: "text",
                  value: tenant.primary_host,
                },
                {
                  label: "اسم الموقع",
                  placeholder: "اسم الموقع",
                  name: "site_name",
                  type: "text",
                  value: tenant.site_name,
                },
              ],
            }}
            onSubmit={async (values) => {
              const { logo, ...v } = values
              await toast.promise(updateContent("tenant.tenant", id, values), {
                loading: "جاري التحديث",
                success: "تم التحديث بنجاح",
                error: "حدث خطا",
              })
            }}
          />
        </>
      )}

      <div>
        نسخ بيانات الدخول
        <CopyButton
          value={`
        رابط موقع الادارة :
        https://accounts.vetrinas.ly/
        
        اسم المستخدم  
        ${tenant?.admins?.[0].email}
        
        الرمز السري
        12345678      
        `}
        />
      </div>

      {isAdmin && (
        <a rel="noreferrer" target="_blank" href={`${baseURL}/admin/plugins/content-manager/collectionType/application::tenant.tenant/${id}`}>
          go to strapi page
        </a>
      )}

      <a style={{ display: "block", marginTop: 30 }} rel="noreferrer" target="_blank" href={`https://${tenant?.host}`}>
        visit website
      </a>

      <Divider />

      <h2>الاشتراك</h2>
      <p>
        انتهاء الاشتراك:
        <strong>{tenant && getSubscripitonStatusString(tenant)}</strong>
      </p>
      {/* renew subscription buttons */}
      {isAdmin && (
        <Stack mt={3} spacing={1} direction="row" justifyContent="space-between">
          <Button onClick={() => renew(30, 100, "شهري")}>تجديد شهري</Button>
          <Button onClick={() => renew(30 * 6, 510, "نصف سنوي")}>تجديد نصف سنوي</Button>
          <Button onClick={() => renew(365, 960, "سنوي")}>تجديد سنوي</Button>
          <Button onClick={() => tempRenew()}>تجديد مؤقت</Button>
        </Stack>
      )}

      <Divider />

      {/* stats section */}
      {isAdmin && (
        <Box my={2}>
          <h3 style={{ marginTop: 55 }}>شوية احصائيات</h3>
          <Grid spacing={3} container>
            <StatsItem label="طلبات تم توصيلها" value={stats.deliveredOrdersCount} />
            <StatsItem label="المبيعات" value={stats.income} />
            <StatsItem label="بالاجل" value={stats.activeOrdersIncome} />
            <StatsItem label="عدد الطلبات الفعالة" value={stats.activeOrdersCount} />
            <StatsItem label="تاريخ اخر طلب" value={stats.lastOrderDate} />
            <StatsItem label="عدد المنتجات" value={stats.productsCount} />
          </Grid>
        </Box>
      )}

      {isAdmin && (
        <>
          <AccordionWrapper parent={"الفواتير"}>
            <TenantPaymentsList siteId={tenant.id} refetch={fetchTenant} admins={admins} payments={tenant.payments} />
          </AccordionWrapper>
          <AccordionWrapper parent={"الادمنز"}>
            <Button
              onClick={() => {
                const name = prompt("enter name without spaces")
                handlePromise(
                  addTenantAdmin({
                    name,
                    email: `${name}@vetrinas.ly`,
                    tenantId: tenant.id,
                  })
                )
              }}
            >
              اضافة ادمن
            </Button>
            {tenant?.admins.map((admin) => {
              return (
                <Stack alignItems="center" direction="row" justifyContent={"space-between"} key={admin.id}>
                  <Stack sx={{ color: admin.blocked && "red" }} spacing={1} direction="row">
                    <Typography>{admin.email}</Typography>
                    <CopyButton value={admin.email} />
                  </Stack>
                  <Stack spacing={1} direction="row">
                    <Button
                      onClick={async () => {
                        const res = await makeHttpRequest(`/vetrina-admin/tenants/admins/${admin.id}`)

                        navigator.clipboard.writeText(res)
                        toast.success("copied")
                      }}
                    >
                      Token
                    </Button>
                    <Button
                      onClick={async () => {
                        const res = await makeHttpRequest(`/vetrina-admin/tenants/admins/${admin.id}`)

                        window.open(`https://accounts.vetrinas.ly?token=${res}`, "_blank")
                      }}
                    >
                      login as
                    </Button>
                    <Button onClick={() => confirmAction(() => handlePromise(updateUserPassword("12345678", admin.id)))}>Reset password</Button>
                  </Stack>
                </Stack>
              )
            })}
          </AccordionWrapper>
        </>
      )}
      {/* add product */}
      <AccordionWrapper parent={"اضافة منتج"}>
        <DynamicForm
          onSubmit={async (v) => {
            try {
              await makeHttpRequest(
                "/vetrina-admin/products",
                "post",
                {
                  price: v.price,
                  name: v.name,
                  addedQuantity: v.quantity,
                  cost_per_unit: v.cost_per_unit,
                  TenantID: id,
                },
                null,
                {
                  main_image: v.main_image,
                }
              )
              toast.success("product created")
            } catch (err) {
              toast.error("error occur, check logs")
              console.log(err)
            }
          }}
          config={{
            inputs: [
              {
                name: "main_image",
                component: ({ formik, input }) => {
                  return <ImageCropPicker label={"صورة المنتج"} setFile={(file) => formik.setFieldValue(input.name, file)} />
                },
              },
              {
                label: "اسم المنتج",
                name: "name",
                type: "text",
                value: "منتج تجريبي",
              },
              {
                label: "السعر",
                name: "price",
                type: "number",
                value: "100",
              },
              {
                label: "تكلفة القطعة",
                name: "cost_per_unit",
                type: "number",
                value: "50",
              },
              {
                label: "الكمية",
                name: "quantity",
                type: "number",
                value: "10",
              },
            ],
          }}
        />
      </AccordionWrapper>
      {/* products properties settings */}
      <AccordionWrapper parent={"اختيارات المنتجات"}>
        <section style={{ marginTop: 28 }}>
          <Divider />
          <h4>Properties</h4>

          {properties.length > 0 ? (
            <ul>
              {properties.map((property) => {
                return (
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href={`${baseURL}/admin/plugins/content-manager/collectionType/application::property.property/${property.id}`}
                  >
                    <div key={property.id}>{property.name}</div>;
                  </a>
                )
              })}
            </ul>
          ) : (
            <p>no properties</p>
          )}
          <Button variant="contained" onClick={() => setstate(true)}>
            Add property
          </Button>
          <AddPropertyModal onCreate={() => {}} isVisible={state} setVisibility={setstate} tenantId={id} />
        </section>
      </AccordionWrapper>

      {/* e-payment settings */}
      <AccordionWrapper parent={"اعدادات الدفع الالكتروني"}>
        <section>
          <Stack spacing={4} direction="row">
            <a target="_blank" href="https://merchant.pay.net.ly/">
              t-lync portal
            </a>
            <a target="_blank" href="https://www.notion.so/a2b8421b3e45493f839470d37bab38f7">
              tutorial
            </a>
          </Stack>
          {tenant && (
            <DynamicForm
              submitButtonText="تحديث"
              config={{
                inputs: [
                  {
                    label: "رمز المتجر",
                    name: "storeId",
                    type: "text",
                    validation: string().required("يرجي ادخال رمز المتجر"),
                    value: tenant.config?.t_lync?.storeId ?? "",
                  },
                  {
                    label: "Api key",
                    name: "apiKey",
                    type: "text",
                    validation: string().required("يرجي ادخال التوكن الخاص بالمتجر"),
                    value: tenant.config?.t_lync?.apiKey ?? "",
                  },
                  {
                    label: "شامل مصاريف الشحن",
                    name: "include_delivery_price",
                    type: "checkbox",
                    value: tenant.config?.t_lync?.include_delivery_price ?? true,
                  },
                ],
              }}
              onSubmit={async (v) => {
                try {
                  await makeHttpRequest("/tenants/" + tenant.id, "put", {
                    config: {
                      ...tenant.config,
                      t_lync: {
                        storeId: v.storeId,
                        apiKey: v.apiKey,
                        include_delivery_price: v.include_delivery_price,
                      },
                    },
                  })
                  toast.success("updated successfully")
                } catch (err) {
                  toast.error("error occur, check logs")
                  console.log(err)
                }
              }}
            />
          )}
          <Divider sx={{ my: 2 }} />
          <Typography variant="h4" sx={{ my: 2 }}>
            Facebook pixel settings
          </Typography>

          {tenant && (
            <DynamicForm
              submitButtonText="تحديث"
              config={{
                inputs: [
                  {
                    label: "pixelId",
                    name: "pixelId",
                    type: "text",
                    value: tenant.config?.fb_pixel?.id ?? "",
                  },
                  {
                    label: "domain verification code",
                    name: "facebook_domain_verification",
                    type: "text",
                    value: tenant.config?.fb_pixel?.facebook_domain_verification ?? "",
                  },
                ],
              }}
              onSubmit={async (v) => {
                try {
                  await makeHttpRequest("/tenants/" + tenant.id, "put", {
                    config: {
                      ...tenant.config,
                      fb_pixel: {
                        id: v.pixelId,
                        facebook_domain_verification: v.facebook_domain_verification,
                      },
                    },
                  })
                  toast.success("updated successfully")
                } catch (err) {
                  toast.error("error occur, check logs")
                  console.log(err)
                }
              }}
            />
          )}
        </section>
      </AccordionWrapper>

      <AccordionWrapper parent={"Beta features"}>
        <TenantBetaFeaturesForm tenant={tenant} />
      </AccordionWrapper>

      <AccordionWrapper parent={"اعدادات المتجر"}>
        <TenantConfigForm tenant={tenant} refetch={fetchTenant} />
      </AccordionWrapper>
      {isAdmin && (
        <Card sx={{ p: 4, my: 4 }}>
          <Link style={{ color: "black" }} to={`/tenants/${id}/products`}>
            اضافة مجموعة منتجات
          </Link>
        </Card>
      )}

      {isAdmin && (
        <Button sx={{ mt: 2 }} onClick={() => confirmAction(() => handlePromise(clearStock(tenant.id, confirm("هل تريد حذف المصاريف؟"))))}>
          تصفير الكميات
        </Button>
      )}
    </Container>
  )
}
